.login-modal {
  &__error-message {
    color: red;
    font-weight: bold;
  }
}

.login-form {
  &__field {
    padding: 10px;
  }
  &__field-label {
    margin-right: 10px;
  }
}
