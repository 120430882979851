.initial-page {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__button-list {
    display: flex;
    flex: 1;
  }

  &__button-container {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__button {
    cursor: pointer;
    width: 50%;
  }

  &__button-text {
    margin-top: 20px;
    font-weight: bold;
    font-size: 20px;
  }
}

.app-bar {
  z-index: 1;
  display: flex;
  flex-direction: row;
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #c4d8e2;
  padding: 16px;
  box-sizing: border-box;
  align-items: center;

  &__title {
    font-weight: bold;
    color: #03254c;
    flex: 1;
  }

  &__phrase-info {
    display: flex;
    flex: 1;
  }

  &__phrase-info-label {
    font-weight: bold;
    margin-right: 10px;
  }

  &__user-info {
    margin-right: 10px;
    &--need-login {
      color: gray;
    }
  }

  &__button {
    padding: 5px;
    margin-left: 10px;
    background-color: transparent;
    border: 1px solid gray;
    cursor: pointer;

    &:disabled {
      border: 1px solid darkgray;
      cursor: default;
    }
  }

  &__save-button-container {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__save-button {
    width: 150px;
    background-color: #c4d8e2;

    &:disabled {
      border: 1px solid darkgray;
      cursor: default;
    }
  }

  &__save-as-new-button {
    position: absolute;
    top: 100%;
  }

  &__phrase-title__input {
    outline: none;
    border: 0;
    border-bottom: 1px solid gray;
    background-color: transparent;
    width: 50%;
  }
}

.right-side-bar {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid gray;

  &__tab {
  }

  &__tab-menu {
    background-color: transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid gray;
    padding: 5px;

    &--current {
      border-bottom: none;
      font-weight: bold;
    }
  }

  &__content {
    height: 100%;
    overflow: auto;
    padding: 20px;
  }
}

.editing-page {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    height: 100%;
    overflow: auto;
  }

  &__content-left {
    height: 100%;
    overflow: auto;
    flex-grow: 1;
  }

  &__content-section {
    margin: 10px;
  }

  &__content-section-title {
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  // spinel 의 style 보다 더 세게 걸어주기 위해서 .editing-page 안에 넣어둠
  .text-template-editor {
    display: block;
  }

  // 맨 앞에 있고 뒤에 다른 text 가 없을 때
  .text-component__wrapper:first-child:not(:has(~ .text-component__wrapper)) {
    .text-component:empty {
      display: block;

      // placeholder 구현
      &:not(:focus)::before {
        content: "(Click here to write your reading phrase)";
        color: #999999;
        cursor: text;
      }

      // pseudo DOM 이 없어져 DOM element children 이 바뀌면 contenteditable 의 focus 가 사라져서 pseudo DOM 을 보존해야함
      // 내용은 보이지 않고 보존하기 위해 content: ""; 로 처리
      &:focus::before {
        content: "";
      }
    }
  }
}

.measurement-menu {
  &__field-select {
    min-width: 200px;
    max-width: 280px;
  }
}