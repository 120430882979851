.select-root-concept-modal {
  &__confirm-button {
    background: transparent;
    border: 1px solid gray;
    cursor: pointer;

    &:disabled {
      background: #eeeeee;
      border: 1px solid #eeeeee;
      cursor: default;
    }
  }
}