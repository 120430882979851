.modal {
  flex-direction: column;
  width: 80%;
  height: calc(100% - 30px * 2);
  padding: 0;
  border: 1px solid darkgray;
  background-color: white;
  overflow: auto;
  &:modal {
    display: flex;
  }

  &--small {
    width: fit-content;
    height: fit-content;
    max-width: 80%;
    max-height: calc(100% - 60px);
  }

  &::backdrop {
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  &__header {
    position: relative;
    padding: 12px;
    display: flex;
  }

  &__title {
    flex: 1;
    font-weight: bold;
    font-size: 20px;
  }

  &__button {
    background-color: transparent;
    border-style: none;
    cursor: pointer;
  }

  &__content {
    overflow: auto;
    flex: 1;
    padding: 12px;
  }

  &__action {
    padding: 12px;
  }
}
