.save-reading-phrase-modal {
  &__save-button {
    background: transparent;
    border: 1px solid gray;
    cursor: pointer;
  }

  &__error-message {
    color: red;
    font-weight: bold;
  }
}

.reading-phrase-viewer {
  &__field {
    font-size: 16px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__governance {
    font-size: 12px;
    margin-left: 10px;
  }
  &__field-title {
    font-size: 20px;
  }
  &__field-label {
    font-weight: bold;
    margin-right: 10px;
  }
}