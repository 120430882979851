.load-reading-phrase-modal {
  &__confirm-button {
    background: transparent;
    border: 1px solid gray;
    cursor: pointer;
    margin: 2px;

    &:disabled {
      background: #eeeeee;
      border: 1px solid #eeeeee;
      cursor: default;
    }
  }
  &__delete-button {
    background: transparent;
    border: 1px solid gray;
    cursor: pointer;
    margin: 2px;

    &:disabled {
      background: #eeeeee;
      border: 1px solid #eeeeee;
      cursor: default;
    }
  }
}