.change-public-confirm-modal {
  &__button {
    background: transparent;
    border: 1px solid gray;
    cursor: pointer;
  }

  &__warning-message {
    color: darkblue;
  }

  &__error-message {
    color: red;
    font-weight: bold;
  }
}